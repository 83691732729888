<!--
 * @Author: PC
 * @Date: 2022-04-02 15:58:37
 * @LastEditTime: 2022-09-15 13:03:48
 * @Description: 请您输入文件描述
-->
<template>
  <div class="rohui_home_right_model">
    <div class="rohui_right_content">
      <div class="work_space_lease_model">
        <div class="rohui_home_title_model">
          <div class="rohui_home_title">
            <div class="rohui_title_small"></div>
            <span>办公区租赁</span>
          </div>
          <div class="rohui_title_icon">
            <img src="@/assets/images/rohui_indu_title_icon.png" alt="" />
          </div>
        </div>
        <div class="room_lease_content">
          <ul class="building_lease_data_list">
            <li>
              <div class="building_lease_data_item_icon">
                <img src="@/assets/images/lease_icon1.png" alt="" />
              </div>
              <p class="data_item_number">
                <ICountUp v-if="leasePieShow" :delay="1000" :endVal="jobData.office_area"
                  :options="{ separator: ',', decimalPlaces: '1' }" />
                <!-- 16,204.5 -->
              </p>
              <p>办公配套面积(m²)</p>
            </li>
            <li>
              <div class="building_lease_data_item_icon">
                <img src="@/assets/images/lease_icon2.png" alt="" />
              </div>
              <p class="data_item_number">
                <!-- 202 -->
                <ICountUp v-if="leasePieShow" :delay="1000" :endVal="jobData.office_room"
                  :options="{ separator: ',', decimalPlaces: '0' }" />
              </p>
              <p>办公总房间数(间)</p>
            </li>
            <li>
              <div class="building_lease_data_item_icon">
                <img src="@/assets/images/lease_icon3.png" alt="" />
              </div>
              <p class="data_item_number">
                <ICountUp v-if="leasePieShow" :delay="1000" :endVal="jobData.car_num"
                  :options="{ separator: ',', decimalPlaces: '0' }" />
                <!-- 20 -->
              </p>
              <p>车位总数(个)</p>
            </li>
          </ul>
          <div class="lease_pieChart_model">
            <LeasePieChart v-if="leasePieShow" :jobData="jobData" />
          </div>
        </div>
      </div>

      <div class="work_space_lease_data">
        <div class="rohui_home_title_model">
          <div class="rohui_home_title">
            <div class="rohui_title_small"></div>
            <span>办公租赁明细</span>
          </div>
          <div class="rohui_title_icon">
            <img src="@/assets/images/rohui_indu_title_icon.png" alt="" />
          </div>
        </div>
        <div class="work_space_lease_detailed">
          <div class="lease_status_list_model">
            <ul class="lease_status_list">
              <li>已出租</li>
              <li class="no_lease_item">未出租</li>
            </ul>
          </div>
          <div class="lease_detailed_data" v-if="leaseData && leaseData.length > 0">
            <div class="lease_detailed_data_item" v-for="(item, index) in leaseData" :key="index">
              <span class="lease_font_size">{{ item.floor }}</span>
              <!-- <div class="no_lease_schedule">
                                <p class="no_lease_number">{{ String(100 - getPercent(item.roomAllNum, item.leasedNum)).indexOf(".") != -1 ? (100 - getPercent(item.roomAllNum, item.leasedNum)).toFixed(2) : 100 - getPercent(item.roomAllNum, item.leasedNum) }}%</p>
                                <div class="yes_lease_schedule" :style="{ 'width': `${getPercent(item.roomAllNum, item.leasedNum)}%`}">
                                    <p style="margin-left: 10px;overflow: hidden;">{{ getPercent(item.roomAllNum, item.leasedNum) }}%</p>
                                </div>
                            </div> -->
              <div class="no_lease_schedule">
                <p class="no_lease_number">
                  <ICountUp v-if="leasePieShow" :endVal="(100 - getPercent(item.roomAllNum, item.rent))"
                    :options="{ separator: ',', decimalPlaces: '2' }" />
                  %
                </p>
                <div class="yes_lease_schedule" :style="{ width: `${item.perece}%` }">
                  <p style="margin-left: 10px; overflow: hidden">
                    <!-- {{ getPercent(item.roomAllNum, item.leasedNum) }} -->
                    <ICountUp v-if="leasePieShow" :endVal="item.perece"
                      :options="{ separator: ',', decimalPlaces: '2' }" />
                    %
                  </p>
                </div>
              </div>
              <!-- <span class="lease_font_size">{{ item.leasedNum }}/{{ item.roomAllNum }}<span>(间)</span></span> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/css/rohui/roHuiHomeRight.less";
// getOfficeDetail
import { getOfficeArea, getOfficeDetail } from "@/api/rongHui.js";
import LeasePieChart from "@/components/roHui/roHuiHome/LeasePieChart";
import ICountUp from "vue-countup-v2";
export default {
  components: {
    LeasePieChart,
    ICountUp,
  },
  data() {
    return {
      leaseData: [
        /* {
                        id: 1,
                        floorName: '2F',
                        rent: 961.28,
                        roomAllNum: 1958.21,
                    },
                    {
                        id: 2,
                        floorName: '3F',
                        rent: 1231.68,
                        roomAllNum: 2602.67,
                    },
                    {
                        id: 3,
                        floorName: '4F',
                        rent: 2411.7,
                        roomAllNum: 2411.7,
                    },
                    {
                        id: 4,
                        floorName: '5F',
                        rent: 1562.86,
                        roomAllNum: 2539.81,
                    },
                    {
                        id: 5,
                        floorName: '6F',
                        rent: 2489.57,
                        roomAllNum: 2489.57,
                    },
                    {
                        id: 6,
                        floorName: '7F',
                        rent: 1241.65,
                        roomAllNum: 2404.38,
                    },
                    {
                        id: 7,
                        floorName: '8F',
                        rent: 1788.95,
                        roomAllNum: 1788.95,
                    }, */
      ],
      num: 0,
      leasePieShow: false,
      jobData: {
        area_rate: 0,
        car_leasehold: 0,
        car_not_rent: 0,
        car_num: 0,
        car_rate: 0,
        office_area: 0,
        office_room: 0
      },
    };
  },
  mounted() {
    getOfficeArea().then((res) => {
      // console.log("办公租赁", res);
      const { data } = res
      if (data.length > 0) {
        for (let i in data[0]) {
          data[0][i] = Number(data[0][i])
        }
        this.jobData = data[0]
      }
    });

    setTimeout(() => {
      this.leasePieShow = true;
      const _this = this;
      getOfficeDetail().then((data) => {
        // console.log("办公明细", data);
        const _data = data.data;
        _data.map((item) => {
          let _roomAllNum = Number(item.not_rent) + Number(item.rent);
          let _num = _this.getPercent(_roomAllNum, item.rent);
          item.perece = 0;
          item.roomAllNum = _roomAllNum;
          item.rent = Number(item.rent);
          setTimeout(() => {
            item.perece = _num;
          }, 100);
        });
        this.leaseData = _data;
        // console.log(this.leaseData, 121122211);
      });
    }, 1000);
  },
  methods: {
    getPercent(allNum, num) {
      if (this.leasePieShow) {
        num = parseFloat(num);
        allNum = parseFloat(allNum);
        if (isNaN(num) || isNaN(allNum)) {
          return "0";
        }
        return allNum <= 0 ? 0 : Number((Math.round((num / allNum) * 10000) / 100.0));
      }
    },
  },
};
</script>
