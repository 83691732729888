<!--
 * @Author: PC
 * @Date: 2022-04-02 17:35:51
 * @LastEditTime: 2022-04-02 18:00:55
 * @Description: 请您输入文件描述
-->
<template>
  <div class="lease_pieChart_model">
    <div class="office_lease_model">
      <div id="officeLeaseChart" :style="{ width: '180px', height: '180px' }"></div>
      <div class="office_lease_model_text">
        <p class="office_lease_model_num">
          <ICountUp style="font-size: 34px" :delay="1000" :endVal="jobData.area_rate" :options="{ separator: ',', decimalPlaces: '0' }" />
          <span>(%)</span>
        </p>
        <p>出租率</p>
      </div>
      <div class="office_lease_status_model">
        <p>办公面积出租情况</p>
        <ul class="office_lease_status_list">
          <li>已出租</li>
          <li class="office_no_lease_color">未出租</li>
        </ul>
      </div>
    </div>
    <div class="office_lease_model">
      <div id="parkingSpaceLeaseChart" :style="{ width: '180px', height: '180px' }"></div>
      <div class="office_lease_model_text">
        <p class="office_lease_model_num">
          <ICountUp style="font-size: 34px" :delay="1000" :endVal="jobData.car_rate" :options="{ separator: ',', decimalPlaces: '0' }" />
          <span>(%)</span>
        </p>
        <p>出租率</p>
      </div>
      <div class="office_lease_status_model">
        <p>车位出租情况</p>
        <ul class="office_lease_status_list parking_lease_status_list">
          <li>
            <span class="parking_lease_status_name">已出租</span><span class="parking_lease_number_model">{{ jobData.car_leasehold }}(个)</span>
          </li>
          <li class="office_no_lease_color">
            <span class="parking_lease_status_name">未出租</span><span class="parking_lease_number_model">{{ jobData.car_not_rent }}(个)</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import ICountUp from "vue-countup-v2";
export default {
  components: {
    ICountUp,
  },
  props: ["jobData"],
  mounted() {
    const _data = this.jobData;
    this.drawLine(_data.area_rate);
    this.drawLineParking(_data.car_rate);
  },
  methods: {
    drawLine(area) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("officeLeaseChart"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
          orient: "vertical",
        },
        /* color: [
                    'red',
                    'blue'
                ], */
        series: [
          {
            type: "pie",
            radius: [0, "50%"],
            hoverAnimation: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: area, name: "已出租" },
              { value: 100 - area, name: "未出租" },
            ],
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#6a3f1c", "#2d1d0d"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
          {
            type: "pie",
            radius: ["55%", "60%"],
            hoverAnimation: false,
            labelLine: {
              show: false,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "12",
                fontWeight: "bold",
              },
            },
            data: [
              { value: area, name: "已出租" },
              { value: 100 - area, name: "未出租" },
            ],
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#f68d3e", "#2d1d0d"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      });
    },
    drawLineParking(car) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("parkingSpaceLeaseChart"));
      // 绘制图表
      myChart.setOption({
        tooltip: {
          show: false,
        },
        legend: {
          show: false,
          orient: "vertical",
        },
        /* color: [
                    'red',
                    'blue'
                ], */
        series: [
          {
            type: "pie",
            radius: [0, "50%"],
            hoverAnimation: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [
              { value: car, name: "已出租" },
              { value: 100 - car, name: "未出租" },
            ],
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#977951", "#532d08"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
          {
            type: "pie",
            radius: ["55%", "60%"],
            hoverAnimation: false,
            labelLine: {
              show: false,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "12",
                fontWeight: "bold",
              },
            },
            data: [
              { value: car, name: "已出租" },
              { value: 100 - car, name: "未出租" },
            ],
            itemStyle: {
              normal: {
                color: function (colors) {
                  var colorList = ["#fcebbf", "#522c08"];
                  return colorList[colors.dataIndex];
                },
              },
            },
          },
        ],
      });
    },
  },
};
</script>
<style lang="less">
.office_lease_model {
  position: relative;
  width: 180px;
  height: 180px;
  .office_lease_model_text {
    position: absolute;
    height: 61px;
    width: 54px;
    text-align: center;
    top: calc(50% - 61px / 2);
    left: calc(50% - 54px / 2);
    p {
      font-size: 16px;
    }
    .office_lease_model_num {
      font-size: 34px;
      height: 38px;
      span {
        font-size: 12px;
      }
    }
  }
  .office_lease_status_model {
    position: absolute;
    bottom: -45px;
    width: 132px;
    text-align: center;
    left: calc(50% - 132px / 2);
    .office_lease_status_list {
      margin-top: 8px;
      li {
        position: relative;
        width: 47px;
        margin: 0 auto;
        padding-left: 12px;
      }
    }
    .office_lease_status_list li::after {
      content: "";
      position: absolute;
      height: 7px;
      width: 7px;
      background: #f69042;
      top: calc(50% - 7px / 2);
      left: 0;
    }
    .office_lease_status_list .office_no_lease_color::after {
      background: #3e260e;
    }

    .parking_lease_status_list {
      li {
        width: 120px;
      }
      .parking_lease_status_name {
        margin-right: 10px;
      }
      .parking_lease_number_model {
        display: inline-block;
        width: 26px;
      }
    }
    .parking_lease_status_list li::after {
      left: 16px;
    }
  }
}
</style>
