<template>
    <div class="rohui_home_modal_wrap">
        <div class="modal_off_model" @click="roHuiOff">
            <img src="@/assets/images/company_off_icon.png" alt="">
        </div>
        <div class="buidling_content_model">
            <h2>融汇国际大厦</h2>
            <div class="buidling_content_text_box">
                <div class="building_text_item">
                    <p class="rohui_building_two">
                        <img src="@/assets/images/builing_icon1.png" alt="">
                        区位优势
                    </p>
                    <div class="buidling_content_text_model">
                        <p>大厦位于西直门核心商区中国金融科技资源为最密集，条件最为雄厚，研发成果最为丰富的区域。地铁2号、4号、13号线在此汇聚，交通便捷，可顺接二环路、快速通达金融街、中关村。</p>
                    </div>
                </div>
                <div class="building_text_item">
                    <p class="rohui_building_two">
                        <img src="@/assets/images/builing_icon2.png" alt="">
                        附加值高
                    </p>
                    <div class="buidling_content_text_model">
                        <p>大厦受到市区两级政府高度重视，符合入驻标准企业，可享受北京市海淀区相关补贴及奖励政策。</p>
                    </div>
                </div>
                <div class="building_text_item">
                    <p class="rohui_building_two">
                        <img src="@/assets/images/builing_icon3.png" alt="">
                        空气进化设施
                    </p>
                    <div class="buidling_content_text_model">
                        <p>大厦办公区配备新风过滤系统，循环通风，并保证每一间办公室均有自然采光。</p>
                    </div>
                </div>
                <div class="building_text_item">
                    <p class="rohui_building_two">
                        <img src="@/assets/images/builing_icon4.png" alt="">
                        租金及物业费标准
                    </p>
                    <div class="buidling_content_text_model">
                        <p>(1) 租金为10-12元/天/平米（建筑面积）</p>
                        <p>(2) 物业费为30元/月/平米（建筑面积）包括夏季制冷和冬季采暖的能源采用</p>
                    </div>
                </div>
                <div class="building_text_item">
                    <p class="rohui_building_two">
                        <img src="@/assets/images/builing_icon5.png" alt="">
                        公用设施齐全
                    </p>
                    <div class="buidling_content_text_model">
                        <p>(1) 大厦两区大堂独立，配有七部直梯功能独立封闭，得房率高达75%。</p>
                        <p>(2) 配有员工餐厅、咖啡厅、高端宴会厅、多功能会议室、集中办公场所等商配套。</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data () {
            return {

            }
        },
        methods: {
            roHuiOff () {
                this.$store.commit ('changeRohuiShow', false)
            }
        }
    }
</script>
<style lang="less">
    .rohui_home_modal_wrap {
        height: 601px;
        width: 575px;
        background: url('./../../../assets/images/rohui_home_modal_bg.png') no-repeat;
        position: absolute;
        top: calc(50% - 601px / 2);
        left: calc(50% - 575px /2);
        .modal_off_model {
            float: right;
            margin-top: 20px;
            margin-right: 14px;
            cursor: pointer
        }
        .buidling_content_model {
            margin-top: 40px;
            padding: 0 35px;
            h2 {
                letter-spacing: 1px;
                font-size: 24px;
            }
            .rohui_building_two {
                font-size: 18px;
                padding: 8px 0;
                letter-spacing: 2px;
            }
            .buidling_content_text_box {
                height: 490px;
                overflow-y: scroll;
                scrollbar-width: none;
                -ms-overflow-style: none;
                .building_text_item {
                    margin-bottom: 10px;
                }
            }
            .buidling_content_text_model{
                p {
                    text-indent: 2em;
                    letter-spacing: 1px;
                    line-height: 24px;
                    font-size: 14px;
                }
            }
            .buidling_content_text_box::-webkit-scrollbar {display:none}
        }
    }
</style>