import service from '@/utils/http'

/* 融汇数据请求 */

// 办公区租赁
export function getOfficeArea() {
    return service({
        url: 'ronghui_office_area',
        method: 'get'
    })
}

// 办公租赁明细
export function getOfficeDetail() {
    return service({
        url: 'ronghui_office_detail',
        method: 'get'
    })
}


// 园区企业
export function getCompany(params) {
    return service({
        url: 'company_list_fe',
        method: 'get',
        params
    })
}


/* ========================== */