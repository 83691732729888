<template>
    <div class="rohui_home_left_model">
        <div class="rohui_left_content">
            <div class="building_intro_model">
                <div class="rohui_home_title_model">
                    <div class="rohui_home_title">
                        <div class="rohui_title_small"></div>
                        <span>大厦介绍</span>
                    </div>
                    <div class="rohui_title_icon">
                        <img src="@/assets/images/rohui_indu_title_icon.png" alt="">
                    </div>
                </div>
                <div class="rohui_building_text">
                    <h2>融汇国际大厦</h2>
                    <p class="rohui_building_two">北京金融科技创新中心</p>
                    <p class="rohui_content_text">大厦位于北京市西北二环核心商区，总建筑面积45000m²，办公区面积共24500m²。</p>
                    <p class="rohui_content_text">自然采光及通风条件优越，配备多功能会议室、路演厅等公共办公空间，为企业发展提供了全方位服务保障，目前所有配套设施均可投入使用。</p>
                </div>
            </div>

            <div class="rohui_building_merit" @click="showBuildingmodal">
                <ul class="builing_merit_list">
                    <li>
                        <div class="merit_item_title">
                            <img src="@/assets/images/builing_icon1.png" alt="">
                            <p>区位优势</p>
                        </div>
                        <p class="merit_item_text">
                            大厦位于西直门核心商区中国金融科技资源为最密集，条件最为雄厚，研发成果最为丰富的区域。地铁2号、4号、13号线在此汇聚，交通便捷，可顺接二环路、快速通达金融街、中关村。
                        </p>
                    </li>
                    <li>
                        <div class="merit_item_title">
                            <img src="@/assets/images/builing_icon2.png" alt="">
                            <p>附加值高</p>
                        </div>
                        <p class="merit_item_text">
                            大厦受到市区两级政府高度重视，符合入驻标准企业，可享受北京市海淀区相关补贴及奖励政策。
                        </p>
                    </li>
                    <li>
                        <div class="merit_item_title">
                            <img src="@/assets/images/builing_icon3.png" alt="">
                            <p>空气进化设施</p>
                        </div>
                        <p class="merit_item_text">
                            大厦办公区配备新风过滤系统，循环通风，并保证每一间办公室均有自然采光。
                        </p>
                    </li>
                    <li>
                        <div class="merit_item_title">
                            <img src="@/assets/images/builing_icon4.png" alt="">
                            <p>租金及物业费标准</p>
                        </div>
                        <p class="merit_item_text">
                            (1) 租金为10-12元/天/平米（建筑面积）
                            (2) 物业费为30元/月/平米（建筑面积）包括夏季制冷和冬季采暖的能源采用
                        </p>
                    </li>
                    <li>
                        <div class="merit_item_title">
                            <img src="@/assets/images/builing_icon5.png" alt="">
                            <p>公用设施齐全</p>
                        </div>
                        <p class="merit_item_text">
                            (1) 大厦两区大堂独立，配有七部直梯功能独立封闭，得房率高达75%。
                            (2) 配有员工餐厅、咖啡厅、高端宴会厅、多功能会议室、集中办公场所等商配套。
                        </p>
                    </li>
                </ul>
            </div>

            <div class="rohui_builind_planning">
                <div class="rohui_home_title_model">
                    <div class="rohui_home_title">
                        <div class="rohui_title_small"></div>
                        <span>建筑规划</span>
                    </div>
                    <div class="rohui_title_icon">
                        <img src="@/assets/images/rohui_indu_title_icon.png" alt="">
                    </div>
                </div>
                <div class="rohui_planning_content">
                    <div class="edifice_all_area">
                        <div class="edifice_round_model">
                            <img src="@/assets/images/planning_round.png" alt="">
                        </div>
                        <div class="edifice_area_number_model">
                            <p class="edifice_area_number">
                                <!-- 4.5 -->
                                <ICountUp
                                    style="font-size:32px"
                                    :delay="1000"
                                    :endVal="4.5"
                                    :options="{separator: ',', decimalPlaces: '1'}"
                                />
                                <span>(万m²)</span>
                            </p>
                            <p style="font-size: 16px;">总建筑面积</p>
                        </div>
                    </div>
                    <ul class="edifice_detailed_data">
                            <li>
                                <div class="edifice_detailed_item_icon">
                                    <img src="@/assets/images/planning_data_icon1.png" alt="">
                                </div>
                                <div class="edifice_detailed_item_text">
                                    <p class="edifice_detailed_item_number_text">
                                        <!-- 2.45 -->
                                        <ICountUp
                                            :delay="1000"
                                            :endVal="2.45"
                                            :options="{separator: ',', decimalPlaces: '2'}"
                                        />
                                    </p>
                                    <p class="edifice_detailed_item_area_text">总办公区面积(万m²)</p>
                                </div>
                            </li>
                            <li>
                                <div class="edifice_detailed_item_icon">
                                    <img src="@/assets/images/planning_data_icon2.png" alt="">
                                </div>
                                <div class="edifice_detailed_item_text">
                                    <p class="edifice_detailed_item_number_text">
                                        <ICountUp
                                            :delay="1000"
                                            :endVal="0.82"
                                            :options="{separator: ',', decimalPlaces: '2'}"
                                        />
                                    </p>
                                    <p class="edifice_detailed_item_area_text">总商业区面积(万m²)</p>
                                </div>
                            </li>
                            <li>
                                <div class="edifice_detailed_item_icon">
                                    <img src="@/assets/images/planning_data_icon3.png" alt="">
                                </div>
                                <div class="edifice_detailed_item_text">
                                    <p class="edifice_detailed_item_number_text">
                                        <!-- 2.45 -->
                                        <ICountUp
                                            :delay="1000"
                                            :endVal="209"
                                            :options="{separator: ',', decimalPlaces: '0'}"
                                        />
                                    </p>
                                    <p class="edifice_detailed_item_area_text">机动车位(个)</p>
                                </div>
                            </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import '@/assets/css/rohui/roHuiHomeLeft.less'
    import ICountUp from 'vue-countup-v2';
    export default {
        components: {
            ICountUp
        },
        data () {
            return {

            }
        },
        methods: {
            showBuildingmodal () {
                this.$store.commit('changeRohuiShow', true)
            }
        }
    }
</script>